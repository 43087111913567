import React from "react"
import { Seo, PageLayout, PageTitle } from "../components"
import { Container } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import ProfilePicture from "../components/ProfilePicture"

export default ({ data }) => {
  const { author, occupation, designations } = data.site.siteMetadata

  return (
    <PageLayout>
      <PageTitle title="About Me" />
      <Container>
        <ProfilePicture />
        <article className="w-75 m-auto pt-2 text-justify">
          <p className="text-center">
            {designations.map((attr, i) => (
              <span key={attr}>
                &nbsp;<b>{attr}</b>&nbsp;
                {i < designations.length - 1 && <>||</>}
              </span>
            ))}
          </p>
          <p className="i-5 mt-4 pt-2">
            Hello there! Mein Name ist <b>{`${author}`}</b>. Beruflich bin ich{" "}
            <b>{occupation}</b> mit dem Schwerpunkt auf <b>Architektur</b> und{" "}
            <b>Entwicklung</b> von Microservice-Architekturen oder Serverless
            Anwendungen, sowie der kontinuierlichen Verfolgung der DevOps
            Mentalität.
          </p>
          <p className="i-5">
            Außerdem interessiere ich mich noch für die Themen{" "}
            <b>Cybersecurity</b>, <b>Data Science</b> und{" "}
            <b>Machine Learning</b>. Dies zeichnet sich durch die Teilnahme an
            Kaggle Competitions/Kursen oder durch die Teilnahme an der{" "}
            <a
              href="https://aws.amazon.com/de/deepracer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS DeepRacer
            </a>{" "}
            Open Divison aus.
          </p>
          <p className="i-5">
            In meiner Freizeit interessiere ich mich für die Themen Fitness/Powerlifting,
            Mountainbiking und Videospiele/Esports.
          </p>
        </article>
        <article className="w-75 m-auto">
          <hr />
          <p className="unemployed">
            <small>
              Check out my <Link to="/blog">blog</Link> .
            </small>
          </p>
        </article>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        occupation
        author
        designations
      }
    }
  }
`

export const Head = () => {
  return <Seo title="About me" />
}
