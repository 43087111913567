import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "react-bootstrap"

export default () => {
  const data = useStaticQuery(query)
  const { url } = data.gravatar
  const { author } = data.site.siteMetadata
  return <Image src={url} alt={author} style={{ borderRadius: "50%" }} />
}

const query = graphql`
  query {
    site {
      siteMetadata {
        author
      }
    }
    gravatar(email: { eq: "lukas.weiss@exxeta.com" }) {
      url
    }
  }
`
